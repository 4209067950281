import React, { useEffect } from 'react';

// Extend the Window interface
declare global {
  interface Window {
    TiktokAnalyticsObject?: any;
    ttq?: any;
  }
}

export const AnalyticsTiktok: React.FC = () => {
  useEffect(() => {
    // Check if the TikTok Pixel script is already loaded
    if (!window.TiktokAnalyticsObject) {
      (function (w: any, d: Document, t: string) {
        w.TiktokAnalyticsObject = t;
        var ttq = (w[t] = w[t] || []);
        ttq.methods = [
          'page',
          'track',
          'identify',
          'instances',
          'debug',
          'on',
          'off',
          'once',
          'ready',
          'alias',
          'group',
          'enableCookie',
          'disableCookie',
          'holdConsent',
          'revokeConsent',
          'grantConsent',
        ];
        ttq.setAndDefer = function (t: any, e: any) {
          t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        };
        for (var i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
        ttq.instance = function (t: any) {
          for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
          return e;
        };
        ttq.load = function (e: any, n: any) {
          var r = 'https://analytics.tiktok.com/i18n/pixel/events.js',
            o = n && n.partner;
          ttq._i = ttq._i || {};
          ttq._i[e] = [];
          ttq._i[e]._u = r;
          ttq._t = ttq._t || {};
          ttq._t[e] = +new Date();
          ttq._o = ttq._o || {};
          ttq._o[e] = n || {};
          n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = true;
          n.src = r + '?sdkid=' + e + '&lib=' + t;
          e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(n, e);
        };

        ttq.load('CTCRRKJC77U38DUS97OG');
        ttq.page();
      })(window, document, 'ttq');
    }
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://analytics.tiktok.com/i18n/pixel/events.js?sdkid=CTCRRKJC77U38DUS97OG&ev=PageView&noscript=1"
        alt=""
      />
    </noscript>
  );
};