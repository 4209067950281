import React, { useState, useCallback } from 'react';
import { Download, Share2 } from 'lucide-react';
import { VideoSegmentSelector } from './VideoSegmentSelector';
import { SharePreview } from './SharePreview';

interface PostCallReviewProps {
  recordingUrl?: string;
  onShare: () => void;
  onRestart: () => void;
}

export function PostCallReview({ recordingUrl, onShare, onRestart }: PostCallReviewProps) {
  const [isShareMode, setIsShareMode] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState<{ start: number; end: number } | null>(null);
  const [showSharePreview, setShowSharePreview] = useState(false);
  const [showFullVideo, setShowFullVideo] = useState(false);

  const handleSegmentChange = (start: number, end: number) => {
    setSelectedSegment({ start, end });
  };
  const handleShare = useCallback(() => {
    // Implement share functionality
    console.log('Sharing call...');
  }, []);

  const handleShareSnippet = () => {
    if (selectedSegment && recordingUrl) {
      setShowSharePreview(true);
    }
  };

  const handleFullVideoShare = () => {
    if (recordingUrl) {
      setShowFullVideo(true);
    }
  };

  if (showSharePreview && recordingUrl && selectedSegment) {
    return (
      <SharePreview
        videoUrl={recordingUrl}
        startTime={selectedSegment.start}
        endTime={selectedSegment.end}
        onBack={() => setShowSharePreview(false)}
      />
    );
  }

  if (showFullVideo && recordingUrl) {
    return (
      <SharePreview
        videoUrl={recordingUrl}
        isFullVideo
        onBack={() => setShowFullVideo(false)}
      />
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center overflow-y-auto 
                  bg-gradient-to-b from-red-900 to-red-950">
      <div className="w-full max-w-md px-6 py-8 space-y-6">
        {/* Header */}
        <div className="text-center space-y-4">
          <div className="inline-block animate-bounce">
            <span className="text-6xl">🎄</span>
          </div>
          <h2 className="text-3xl font-bold text-white">
            Ho Ho Ho! What a Magical Call!
          </h2>
          <div className="flex justify-center gap-1">
            {[...Array(5)].map((_, i) => (
              <span key={i} className="text-2xl">⭐️</span>
            ))}
          </div>
        </div>

        {/* Action Buttons and Preview */}
        {recordingUrl && (
          <div className="space-y-4">
            {/* Share 30s Snippet */}
            <div className="space-y-4">
              <button
                onClick={() => setIsShareMode(!isShareMode)}
                className="w-full h-14 bg-red-500 text-white rounded-xl hover:bg-red-600 
                         transition-all transform active:scale-98 flex items-center 
                         justify-center gap-2 text-lg font-medium shadow-lg"
              >
                <Share2 className="w-5 h-5" />
                Share 30s Snippet
              </button>

              {/* Video Preview and Segment Selection */}
              {isShareMode && (
                <div className="animate-fade-in space-y-4 bg-black/20 rounded-xl p-4">
                  <VideoSegmentSelector
                    videoUrl={recordingUrl}
                    maxDuration={180}
                    onSegmentChange={handleSegmentChange}
                  />
                  <button
                    onClick={handleShareSnippet}
                    className="w-full h-12 bg-red-600 text-white rounded-xl 
                             hover:bg-red-700 transition-all transform active:scale-98 
                             text-base font-medium shadow-lg"
                    disabled={!selectedSegment}
                  >
                    Share Selected Snippet
                  </button>
                </div>
              )}
            </div>

            {/* Download Full Call */}
            <button
              onClick={handleFullVideoShare}
              className="w-full h-14 bg-green-600 text-white rounded-xl hover:bg-green-700 
                       transition-all transform active:scale-98 flex items-center 
                       justify-center gap-2 text-lg font-medium shadow-lg"
            >
              <Download className="w-5 h-5" />
              Download Full Call
            </button>

            {/* Skip Link */}
            <button
              onClick={onRestart}
              className="w-full text-white/70 hover:text-white py-2 transition-colors text-base"
            >
              Skip
            </button>
          </div>
        )}

        {/* Footer */}
        <p className="text-center text-white/80 text-sm pt-4">
          Spread the joy! Share your magical moment with Santa
        </p>
      </div>

      {/* Snow effect */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        <div className="absolute inset-0 animate-snowfall opacity-20">
          <div className="absolute inset-0 bg-repeat bg-[length:700px_700px]" 
               style={{ backgroundImage: `radial-gradient(circle at center, white 0.15%, transparent 0.5%)` }} />
        </div>
      </div>
    </div>
  );
}