import React from "react";

export const PrivacyPolicy: React.FC = () => {
  return (
    <div className="min-h-screen py-10 px-4">
      <div className="max-w-4xl mx-auto text-white glassmorphism shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-50 mb-6">Privacy Policy</h1>
        <p className="text-sm text-gray-100 mb-4">Last Updated: December 5, 2024</p>
        <p className="text-gray-100 leading-relaxed mb-8">
          Shooflyai ("Company," "we," "us," or "our") respects your privacy and is committed to
          protecting the personal information you share with us. This Privacy Policy describes how
          we collect, use, store, disclose, and otherwise process your information when you use our
          websites, mobile applications, and related services, including the "Calls with Santa"
          platform (collectively, the "Services").
        </p>
        <p className="text-gray-100 leading-relaxed mb-8">
          By accessing or using our Services, you acknowledge that you have read, understood, and
          agree to this Privacy Policy and our Terms and Conditions. If you do not agree, please do
          not use the Services.
        </p>

        {/* Section 1 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">1. Scope and Applicability</h2>
          <p className="text-gray-100 leading-relaxed">
            This Privacy Policy applies to all personal information collected by Shooflyai through
            the Services. It does not apply to the practices of third parties that we do not own or
            control, including third-party platforms (such as Meta’s platforms or TikTok) or
            third-party websites, services, or applications that you may access through our
            Services. We encourage you to review the privacy policies of any third-party services
            you use.
          </p>
        </section>

        {/* Section 2 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">2. Information We Collect</h2>

          {/* Subsection A */}
          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-300 mb-3">
              A. Information You Provide Directly:
            </h3>
            <ul className="list-disc list-inside text-gray-100 space-y-2">
              <li>
                <strong>Account Information:</strong> When you register for an account, we may
                collect personal identifiers such as your name, email address, phone number, and
                payment information if you purchase our Services.
              </li>
              <li>
                <strong>User Content:</strong> If you submit or upload content (e.g., messages,
                images, audio recordings) through the Services, we collect that content and any
                associated metadata.
              </li>
              <li>
                <strong>Communications:</strong> If you contact us (e.g., via email or chat
                support), we may collect your contact details and the content of your communication.
              </li>
            </ul>
          </div>

          {/* Subsection B */}
          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-300 mb-3">
              B. Information We Collect Automatically:
            </h3>
            <ul className="list-disc list-inside text-gray-100 space-y-2">
              <li>
                <strong>Usage Data:</strong> We automatically collect information about your
                interactions with the Services, such as your IP address, device type, operating
                system, browser type, referring URLs, pages viewed, and the dates and times of your
                visits.
              </li>
              <li>
                <strong>Cookies and Similar Technologies:</strong> We use cookies, web beacons, and
                similar tracking technologies to recognize you, remember your preferences, and
                customize your experience. For more details, please see the "Cookies and Tracking
                Technologies" section below.
              </li>
            </ul>
          </div>

          {/* Subsection C */}
          <div>
            <h3 className="text-lg font-medium text-gray-300 mb-3">
              C. Information from Third Parties:
            </h3>
            <p className="text-gray-100 leading-relaxed">
              We may receive information about you from third-party platforms or service providers,
              such as payment processors or social media platforms, if you interact with our
              Services through these channels and have authorized them to share your information
              with us.
            </p>
          </div>
        </section>
        <p className="text-gray-100 leading-relaxed mb-8">
          Shooflyai ("Company," "we," "us," or "our") respects your privacy and is committed to
          protecting the personal information you share with us. This Privacy Policy describes how
          we collect, use, store, disclose, and otherwise process your information when you use our
          websites, mobile applications, and related services, including the "Calls with Santa"
          platform (collectively, the "Services").
        </p>

        {/* Section 3 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            3. Information About Children
          </h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            Our Services, especially the "Calls with Santa" platform, may appeal to families and
            children under the age of 13 (or the age specified by applicable law). We are committed
            to complying with the U.S. Children’s Online Privacy Protection Act ("COPPA") and other
            applicable laws protecting children’s privacy.
          </p>
          <div>
            <h3 className="text-lg font-medium text-gray-300 mb-3">Parental Consent:</h3>
            <p className="text-gray-100 leading-relaxed">
              If you are a parent or guardian and wish to allow your child to use the Services, you
              may be required to provide verifiable parental consent before we collect any personal
              information from your child. If we learn that we have collected personal information
              from a child under applicable age limits without parental consent, we will delete that
              information as soon as reasonably practicable. For more information on how to provide
              or revoke consent, please contact us at the information provided below.
            </p>
          </div>
        </section>

        {/* Section 4 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">4. How We Use Your Information</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            We use the personal information we collect for the following purposes:
          </p>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>
              <strong>Providing and Personalizing the Services:</strong> To operate, maintain, and
              improve the Services, including delivering personalized "Santa" calls using AI-driven
              technology and storing call recordings for your later enjoyment.
            </li>
            <li>
              <strong>Account Management:</strong> To create and manage your account, process
              payments, and authenticate your access to the Services.
            </li>
            <li>
              <strong>Communications:</strong> To respond to your inquiries, provide customer
              support, send updates about the Services, and communicate changes to our policies and
              terms.
            </li>
            <li>
              <strong>Analytics and Improvements:</strong> To analyze usage trends, monitor
              performance, and improve the quality, safety, and security of the Services.
            </li>
            <li>
              <strong>Legal and Compliance:</strong> To comply with applicable laws, regulations,
              and legal requests; to enforce our Terms and Conditions; and to protect our rights,
              property, or safety, and that of our users or the public.
            </li>
          </ul>
        </section>

        {/* Section 5 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">5. Cookies and Tracking Technologies</h2>
          <p className="text-gray-100 leading-relaxed">
            We use cookies and similar technologies to collect information about how you interact
            with the Services and to remember your preferences. You can control cookies through your
            browser settings and other tools. However, disabling cookies may limit your ability to
            use certain features of the Services.
          </p>
        </section>

        {/* Section 6 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">6. How We Share Your Information</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            We may share your personal information in the following circumstances:
          </p>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>
              <strong>Service Providers:</strong> We engage third-party service providers to assist
              with various functions (e.g., hosting, payment processing, analytics, customer
              support). They have access to your personal information only to perform their services
              on our behalf and are obligated not to disclose or use it for other purposes.
            </li>
          </ul>
        </section>
        {/* Section 6 (continued) */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            6. How We Share Your Information (continued)
          </h2>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>
              <strong>Legal Compliance and Protection:</strong> We may disclose your information if
              required by law or in response to valid legal requests, such as a subpoena, or to
              protect the rights, property, and safety of Shooflyai, our users, or the public.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger, acquisition,
              reorganization, or sale of all or a portion of our assets, your personal information
              may be transferred as part of that transaction.
            </li>
            <li>
              <strong>With Your Consent:</strong> We may share your information for other purposes
              with your explicit consent.
            </li>
          </ul>
          <p className="text-gray-100 leading-relaxed mt-4">
            We do not sell your personal information to third parties.
          </p>
        </section>

        {/* Section 7 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            7. Third-Party Platforms and Services
          </h2>
          <p className="text-gray-100 leading-relaxed">
            When you interact with our Services through third-party platforms (e.g., Meta or
            TikTok), please note that these platforms may also collect and process your
            information. This Privacy Policy does not govern their practices. We encourage you to
            review the privacy policies of any third-party platforms or services you use in
            connection with our Services.
          </p>
        </section>

        {/* Section 8 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">8. Data Retention</h2>
          <p className="text-gray-100 leading-relaxed">
            We retain your personal information only for as long as necessary to fulfill the
            purposes for which it was collected, to comply with our legal obligations, to resolve
            disputes, and to enforce our agreements. When we no longer require your personal
            information, we will securely delete or anonymize it.
          </p>
        </section>

        {/* Section 9 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">9. Data Security</h2>
          <p className="text-gray-100 leading-relaxed">
            We implement commercially reasonable administrative, technical, and physical security
            measures to help protect your personal information from unauthorized access, loss,
            misuse, or alteration. However, no method of transmission over the internet or
            electronic storage is completely secure. You use the Services and provide your
            information at your own risk.
          </p>
        </section>

        {/* Section 10 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            10. International Data Transfers
          </h2>
          <p className="text-gray-100 leading-relaxed">
            If you access the Services from outside the United States, please note that your
            information may be transferred to and processed in the United States or other countries
            where our service providers operate. These countries may have different data protection
            laws than your home jurisdiction. We take steps to ensure that the transfer of your
            information is lawful and that your information is adequately protected.
          </p>
        </section>

        {/* Section 11 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">11. Your Rights and Choices</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            Depending on your jurisdiction, you may have certain rights regarding your personal
            information, such as the right to access, correct, or delete your data, or to object to
            or restrict certain processing. To exercise these rights, please contact us at the
            information provided below. We may require verification of your identity before
            fulfilling your request.
          </p>
          <p className="text-gray-100 leading-relaxed">
            If you are a parent or guardian who has provided consent for your child’s use of the
            Services, you may review and request the deletion of your child’s personal information
            at any time.
          </p>
        </section>

        {/* Section 12 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            12. Changes to this Privacy Policy
          </h2>
          <p className="text-gray-100 leading-relaxed">
            We may update this Privacy Policy from time to time. Any changes will be effective when
            we post the revised policy on our website, with a new "Last Updated" date. Your
            continued use of the Services after any such changes constitutes your acceptance of the
            updated Privacy Policy. We encourage you to review this Privacy Policy periodically.
          </p>
        </section>

        {/* Section 13 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            13. Contact Information
          </h2>
          <p className="text-gray-100 leading-relaxed">
            If you have any questions, comments, or concerns about this Privacy Policy or our data
            practices, or if you would like to exercise any of your rights, please contact us at:
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Email:</strong> santa.support@shooflyai.com
          </p>
        </section>

        <p className="text-gray-100 leading-relaxed">
          By using our Services, you acknowledge that you have read, understood, and agree to the
          collection and use of your information as described in this Privacy Policy.
        </p>
      </div>
    </div>
  );
};