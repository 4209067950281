import React from "react";

export const RefundPolicy: React.FC = () => {
  return (
    <div className="min-h-screen py-10 px-4">
      <div className="max-w-4xl mx-auto glassmorphism text-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-50 mb-6">Refund Policy</h1>
        <p className="text-sm text-gray-100 mb-4">Last Updated: December 5, 2024</p>

        {/* Section 1 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            1. Personalized Nature of Services
          </h2>
          <p className="text-gray-100 leading-relaxed">
            Our Services often involve creating custom and personalized content, including
            AI-driven, virtual "Santa" calls tailored to your selected preferences. Due to the
            bespoke and time-sensitive nature of these offerings, once we begin production or
            delivery of the Service, the sale is generally final.
          </p>
        </section>

        {/* Section 2 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">2. All Sales Are Final</h2>
          <p className="text-gray-100 leading-relaxed">
            Except as required by applicable law or as otherwise stated in this Refund Policy, we
            do not provide refunds or credits once you have purchased and received the Service. By
            making a purchase, you acknowledge and agree that you are not entitled to a refund of
            any kind, unless we determine otherwise at our sole discretion.
          </p>
        </section>

        {/* Section 3 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">3. Exceptional Circumstances</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            While our default policy is that all sales are final, we may consider refunds under
            exceptional circumstances, including but not limited to:
          </p>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>
              <strong>Technical Issues:</strong> If a technical error attributable to us prevents
              you from accessing or using the Service and we cannot resolve the issue within a
              reasonable timeframe.
            </li>
            <li>
              <strong>Incorrect or Defective Deliverables:</strong> In the event that the delivered
              content is significantly different from what you purchased, due to an error on our
              part (e.g., wrong call recording, corrupted file), and we cannot remedy the issue
              promptly.
            </li>
          </ul>
          <p className="text-gray-100 leading-relaxed mt-4">
            If you believe you are entitled to a refund based on an exceptional circumstance, please
            contact us at the email address provided below with a detailed explanation. We will
            review each request on a case-by-case basis and may require additional information from
            you. Our decision to grant or deny a refund is made at our sole discretion and is final.
          </p>
        </section>

        {/* Section 4 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            4. Compliance with Applicable Laws
          </h2>
          <p className="text-gray-100 leading-relaxed">
            Certain jurisdictions may have consumer protection laws that provide you with
            additional rights. In such cases, we will comply with applicable laws regarding refunds.
            If local law requires a refund under specific circumstances, we will honor it as
            required by law.
          </p>
        </section>

        {/* Section 5 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            5. No Obligation to Issue Future Refunds
          </h2>
          <p className="text-gray-100 leading-relaxed">
            The approval of any single refund request does not obligate us to issue future refunds
            under similar circumstances. Each request is treated as unique, and our decision in one
            case does not set a precedent.
          </p>
        </section>

        {/* Section 6 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">6. Contact Information</h2>
          <p className="text-gray-100 leading-relaxed">
            If you have questions or concerns regarding this Refund Policy or wish to request an
            exception as described above, please contact us:
          </p>
          <p className="text-gray-100 leading-relaxed mt-2">
            <strong>Email:</strong> santa.support@shooflyai.com
          </p>
        </section>

        {/* Section 7 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-300 mb-4">
            7. Changes to This Refund Policy
          </h2>
          <p className="text-gray-100 leading-relaxed">
            We may update this Refund Policy from time to time. Any changes will be effective upon
            posting the revised policy on our website with an updated "Last Updated" date. Your
            continued use of the Services after any changes constitutes your acceptance of the
            revised Refund Policy.
          </p>
        </section>
      </div>
    </div>
  );
};