import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { BACKGROUNDS } from '../../types/pro/backgrounds';

interface BackgroundCarouselProps {
  onSelect: (background: string) => void;
}

export function BackgroundCarousel({ onSelect }: BackgroundCarouselProps) {
  const [startIndex, setStartIndex] = useState(0);

  const handlePrevious = () => {
    setStartIndex((prev) => (prev === 0 ? BACKGROUNDS.length - 3 : prev - 1));
  };

  const handleNext = () => {
    setStartIndex((prev) => (prev === BACKGROUNDS.length - 3 ? 0 : prev + 1));
  };

  const visibleBackgrounds = BACKGROUNDS.slice(startIndex, startIndex + 3);

  return (
    <div className="absolute top-[75%] left-0 right-0 z-20 px-4">
      <div className="relative max-w-md mx-auto">
        <div className="flex items-center">
          <button
            onClick={handlePrevious}
            className="p-1 bg-black/50 rounded-full backdrop-blur-sm text-white/80 
                     hover:text-white transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>

          <div className="flex-1 overflow-hidden mx-2">
            <div className="flex gap-2 justify-center">
              {visibleBackgrounds.map((bg) => (
                <button
                  key={bg.id}
                  onClick={() => onSelect(bg.url)}
                  className="relative flex-shrink-0 w-16 h-16 rounded-lg overflow-hidden 
                           group focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  <img
                    src={bg.thumbnail}
                    alt={bg.label}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/50 group-hover:bg-black/30 
                                transition-colors duration-200" />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <span className="text-white text-xs font-medium text-center px-1">
                      {bg.label}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          </div>

          <button
            onClick={handleNext}
            className="p-1 bg-black/50 rounded-full backdrop-blur-sm text-white/80 
                     hover:text-white transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}