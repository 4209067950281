import React from 'react';
import { Signal, Clock } from 'lucide-react';

interface CallInfoProps {
  duration: string;
}

export function CallInfo({ duration }: CallInfoProps) {
  return (
    <div className="p-4 bg-gradient-to-b from-black/70 to-transparent">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <h2 className="text-white font-semibold text-base">Santa's Workshop</h2>
          <div className="flex items-center space-x-1">
            <Signal className="w-4 h-4 text-green-400" />
            <span className="text-sm text-green-400">Excellent</span>
          </div>
        </div>
        {/* <div className="flex items-center space-x-2 text-white">
          <Clock className="w-4 h-4" />
          <span className="text-sm">{duration}</span>
        </div> */}
      </div>
    </div>
  );
}