import React, { useEffect } from "react";

interface VolumeBarsProps {
  soundLevel: number; // Average sound level from 0 to 255
  maxBars: number; // Maximum number of bars to display
}

export const VolumeBars: React.FC<VolumeBarsProps> = ({
  soundLevel,
  maxBars,
}) => {
  const activeBars = Math.ceil((soundLevel / 100) * maxBars); // Normalize sound level to fit maxBars

  // Log sound level whenever it changes
  useEffect(() => {
    console.log(`Current Sound Level: ${soundLevel}`);
  }, [soundLevel]);

  return (
    <div className="flex items-end gap-[2px] h-10 w-full">
      {[...Array(maxBars)].map((_, index) => {
        const isActive = index < activeBars;

        // Determine the color based on the current sound level
        let backgroundColor = "bg-gray-200"; // Default inactive color
        if (isActive) {
          if (soundLevel < 20) {
            backgroundColor = "bg-red-400"; // Red for too quiet
          } else if (soundLevel >= 20 && soundLevel < 57) {
            backgroundColor = "bg-emerald-400"; // Green for perfect
          } else if (soundLevel >= 57 && soundLevel < 70) {
            backgroundColor = "bg-yellow-400"; // Yellow for slightly loud
          } else {
            backgroundColor = "bg-red-400"; // Red for too loud
          }
        }

        return (
          <div
            key={index}
            className={`w-1.5 rounded-t transition-all duration-150 ${
              isActive ? backgroundColor : "bg-gray-200"
            }`}
            style={{
              height: `${((index + 1) / maxBars) * 100}%`,
            }}
          />
        );
      })}
    </div>
  );
};