import React, { useState, useRef, useEffect } from 'react';
import { Share2, Download, ArrowLeft } from 'lucide-react';
import { BackgroundCarousel } from './BackgroundCarousel';
import { AudioWaveform } from './AudioWaveform';
import { BACKGROUNDS } from '../../types/pro/backgrounds';

interface SharePreviewProps {
  videoUrl: string;
  isFullVideo?: boolean;
  startTime?: number;
  endTime?: number;
  onBack: () => void;
}

export function SharePreview({ 
  videoUrl, 
  isFullVideo = false,
  startTime = 0,
  endTime,
  onBack 
}: SharePreviewProps) {
  const [selectedBackground, setSelectedBackground] = useState(BACKGROUNDS[0].url);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleLoadedMetadata = () => {
      if (startTime) {
        video.currentTime = startTime;
      }
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    return () => video.removeEventListener('loadedmetadata', handleLoadedMetadata);
  }, [startTime]);

  useEffect(() => {
    const video = videoRef.current;
    if (!video || !endTime) return;

    const handleTimeUpdate = () => {
      if (video.currentTime >= endTime) {
        video.currentTime = startTime;
        video.pause();
        setIsPlaying(false);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => video.removeEventListener('timeupdate', handleTimeUpdate);
  }, [startTime, endTime]);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'santa-call.mp4';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading video:', error);
    }
  };

  return (
    <div className="min-h-screen bg-red-950 flex flex-col">
      {/* Header */}
      <div className="p-4 flex items-center justify-between">
        <button onClick={onBack} className="text-white/80 hover:text-white">
          <ArrowLeft className="w-6 h-6" />
        </button>
        <h1 className="text-white font-semibold">
          {isFullVideo ? 'Full Call Preview' : '30s Snippet Preview'}
        </h1>
        <div className="w-6" /> {/* Spacer for alignment */}
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col">
        {/* Video Container with Background */}
        <div className="relative w-full aspect-[9/16] bg-black/10">
          <div 
            className="absolute inset-0"
            style={{
              backgroundImage: `url(${selectedBackground})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              opacity: 0.35
            }}
          />
          
          {/* Centered Video Preview */}
          <div className="absolute inset-0 flex items-center justify-center p-4">
            <div className="w-64 h-64 rounded-lg overflow-hidden border border-white/20 shadow-lg">
              <video
                ref={videoRef}
                src={videoUrl}
                className="w-full h-full object-cover"
                playsInline
                onClick={handlePlayPause}
              />
            </div>
          </div>

          {/* Audio Waveform */}
          <div className="absolute bottom-20 left-0 right-0 px-4">
            <AudioWaveform isActive={isPlaying} />
          </div>

          {/* Video Controls */}
          <div className="absolute bottom-4 left-4 right-4 flex items-center justify-between text-white text-sm">
            <button onClick={handlePlayPause}>
              {isPlaying ? '⏸️' : '▶️'}
            </button>
            <span>{videoRef.current?.currentTime.toFixed(2) || '0:00'} / {videoRef.current?.duration.toFixed(2) || '0:00'}</span>
          </div>
        </div>

        {/* Background Carousel */}
        <div className="mt-auto">
          <BackgroundCarousel onSelect={setSelectedBackground} />
        </div>

        {/* Action Buttons */}
        <div className="p-4 space-y-3">
          <button
            onClick={() => {}} // Share functionality
            className="w-full h-14 bg-red-500 text-white rounded-xl hover:bg-red-600 
                     transition-all flex items-center justify-center gap-2 text-lg 
                     font-medium shadow-lg"
          >
            <Share2 className="w-5 h-5" />
            Share with Friends
          </button>

          <button
            onClick={handleDownload}
            className="w-full h-14 bg-green-600 text-white rounded-xl hover:bg-green-700 
                     transition-all flex items-center justify-center gap-2 text-lg 
                     font-medium shadow-lg"
          >
            <Download className="w-5 h-5" />
            Download Video
          </button>
        </div>
      </div>
    </div>
  );
}