import React, { useState } from "react";
import { Mic, X } from "lucide-react";
import { SoundMeter } from "./../../components/SoundMeter";

interface RecordingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCheckMicrophone: () => void;
}

export function RecordingModal({
  isOpen,
  onClose,
  onConfirm,
  onCheckMicrophone,
}: RecordingModalProps) {
  const [isListening, setIsListening] = useState(false);
  const [isReadyEnabled, setIsReadyEnabled] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  if (!isOpen) return null;

  const handleCheckMicrophone = () => {
    setIsListening(true); // Start listening
    setIsReadyEnabled(false); // Reset readiness state
    setShowWarning(false); // Reset warning banner
    onCheckMicrophone();

    // After 5 seconds, evaluate readiness and show warning if not ready
    setTimeout(() => {
      if (!isReadyEnabled) {
        setShowWarning(true); // Show warning banner if not ready
      }
      setIsListening(false);
    }, 5000);
  };

  const handleSoundLevelChange = (soundLevel: number) => {
    if (soundLevel >= 22) {
      setIsReadyEnabled(true); // Enable "Yes, I'm Ready!" when sound level > 20
    } else {
      // setIsReadyEnabled(false); // Disable if sound level is too low
    }

    if (soundLevel > 53) {
      setShowWarning(true); // Show warning for loud environment
    } else {
      setShowWarning(false); // Hide warning when sound level is appropriate
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div
        className="absolute inset-0 bg-black/80 backdrop-blur-md"
        onClick={onClose}
      />
      <div className="relative bg-gradient-to-b from-red-900 to-red-950 rounded-2xl p-8 w-full max-w-sm mx-auto shadow-2xl border border-red-500/20">
        <button
          onClick={onClose}
          className="absolute -top-2 -right-2 w-8 h-8 bg-red-950 text-white/70 hover:text-white 
                   rounded-full flex items-center justify-center transition-colors border border-red-500/20"
        >
          <X className="w-4 h-4" />
        </button>

        <div className="text-center space-y-4">
          <h3 className="text-2xl font-bold text-white flex items-center justify-center gap-2">
            <Mic className="w-6 h-6" />
            Ready to Talk to Santa?
          </h3>

          <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
            <p className="text-white/90 text-sm leading-relaxed">
              Make sure your microphone is working and accessible before you
              begin your magical chat with Santa.
            </p>
            {showWarning && (
              <div className="mt-4 p-3 bg-yellow-200 text-yellow-900 text-sm font-medium rounded-lg">
                ⚠️ It's a bit loud! Try to lower the noise for a better
                experience or check your microphone setup.
              </div>
            )}
          </div>

          <div className="flex flex-col gap-3 mt-6">
            {isReadyEnabled && (
              <button
                onClick={onConfirm}
                disabled={!isReadyEnabled} // Disable button until microphone is tested
                className={`w-full px-4 py-3 rounded-lg transition-all transform active:scale-98 shadow-lg font-medium ${
                  isReadyEnabled
                    ? "bg-green-500 text-white hover:bg-green-600 hover:shadow-green-500/25"
                    : "bg-gray-500 text-gray-300 cursor-not-allowed"
                }`}
              >
                Yes, I'm Ready!
              </button>
            )}

            <button
              onClick={handleCheckMicrophone}
              className="w-full px-4 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 
                       transition-all transform active:scale-98 backdrop-blur-sm"
            >
              Check My Microphone
            </button>
          </div>
        </div>
        {isListening && (
          <SoundMeter
            isListening={isListening}
            onSoundLevelChange={handleSoundLevelChange} // Pass sound level change handler
          />
        )}

        <div className="absolute inset-0 pointer-events-none overflow-hidden rounded-2xl">
          <div className="absolute inset-0 animate-snowfall opacity-20">
            <div
              className="absolute inset-0 bg-repeat bg-[length:700px_700px]"
              style={{
                backgroundImage: `radial-gradient(circle at center, white 0.15%, transparent 0.5%)`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}