import axios, { AxiosRequestConfig } from 'axios';

const BASE_URL = import.meta.env.VITE_BASE_URL || 'http://localhost:8080';

interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  error?: string;
}

interface UpdateSessionPayload {
  session_id: string;
  action: string;
}

interface GetSessionResponse {
  data: any;
  stripe_data: any;
}

/**
 * Updates session data
 * @param {UpdateSessionPayload} payload - The session update payload
 * @returns {Promise<any>} - The response data
 */
 export async function updateSessionData(
  payload: UpdateSessionPayload
): Promise<any> {
  const url = `${BASE_URL}/v1/webhooks/session_data/update`;
  const response = await apiRequest(url, 'PATCH', payload);
  if (response.success && response.data) {
    return response.data;
  } else {
    throw new Error(response.error || 'Failed to update session data');
  }
}

/**
 * Fetches session data by session ID
 * @param {string} sessionId - The session ID
 * @returns {Promise<GetSessionResponse>} - The session data
 */
export async function getSessionById(
  sessionId: string
): Promise<GetSessionResponse> {
  const url = `${BASE_URL}/v1/webhooks/session_data`;
  const response = await apiRequest<GetSessionResponse>(url, 'POST', { session_id: sessionId });
  if (response.success && response.data) {
    return response.data;
  } else {
    throw new Error(response.error || 'Failed to fetch session data');
  }
}


/**
 * Utility function to handle API requests
 * @param {string} url - The API endpoint URL
 * @param {string} method - The HTTP method (GET, POST, PATCH, etc.)
 * @param {object} [payload] - The request payload (if any)
 * @returns {Promise<ApiResponse>} - The API response
 */
 async function apiRequest<T>(
  url: string,
  method: 'GET' | 'POST' | 'PATCH',
  payload?: object
): Promise<ApiResponse<T>> {
  try {
    const config: AxiosRequestConfig = {
      method,
      url,
      data: payload,
      headers: { 'Content-Type': 'application/json' },
    };

    const response = await axios(config);
    return { success: true, data: response.data };
  } catch (error: any) {
    console.error(`Error during API request to ${url}:`, error);
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

interface TikTokPixelPayload {
  event_source: string;
  event_source_id: string;
  data: {
    event: string;
    event_time: number;
    user: Record<string, any>;
    properties: {
      content_id: string;
      description: string;
    };
    page: {
      url: string;
      referrer: string;
    };
  }[];
}

export async function sendTikTokPixelData(): Promise<void> {
  const url = 'https://cws-api.keyspacestudio.tech/v1/webhooks/tiktok/pixel';

  const payload: TikTokPixelPayload = {
    event_source: 'web',
    event_source_id: 'CTCRRKJC77U38DUS97OG',
    data: [
      {
        event: 'ViewContent',
        event_time: Math.floor(Date.now() / 1000), // Current timestamp in seconds
        user: {}, // Populate user data if needed
        properties: {
          content_id: 'prod_RKKMgj2a4wkXCb',
          description: 'santa call',
        },
        page: {
          url: window.location.href, // Current page URL
          referrer: document.referrer, // Referrer URL
        },
      },
    ],
  };

  try {
    const response = await axios.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('TikTok Pixel data sent successfully:', response.data);
  } catch (error: any) {
    console.error('Error sending TikTok Pixel data:', error.message || error);
  }
}