import { useEffect, useRef } from 'react';
import { Howl } from 'howler';

export function useAmbientSound(isMuted: boolean) {
  const soundRef = useRef<Howl | null>(null);

  useEffect(() => {
    soundRef.current = new Howl({
      src: ['https://assets.mixkit.co/sfx/preview/mixkit-christmas-magic-bells-2978.mp3'],
      loop: true,
      volume: 0.3,
      html5: true
    });

    const workshopSound = new Howl({
      src: ['https://assets.mixkit.co/sfx/preview/mixkit-toy-shop-tinkering-2979.mp3'],
      loop: true,
      volume: 0.1,
      html5: true
    });

    soundRef.current.play();
    workshopSound.play();

    return () => {
      if (soundRef.current) {
        soundRef.current.stop();
      }
      workshopSound.stop();
    };
  }, []);

  useEffect(() => {
    if (soundRef.current) {
      if (isMuted) {
        soundRef.current.mute(true);
      } else {
        soundRef.current.mute(false);
      }
    }
  }, [isMuted]);
}