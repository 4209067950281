export const BACKGROUNDS = [
  {
    id: 'workshop',
    url: 'https://images.unsplash.com/photo-1543589077-47d81606c1bf',
    thumbnail: 'https://images.unsplash.com/photo-1543589077-47d81606c1bf?w=100&h=60&fit=crop',
    label: 'Santa\'s Workshop'
  },
  {
    id: 'fireplace',
    url: 'https://images.unsplash.com/photo-1482517967863-00e15c9b44be',
    thumbnail: 'https://images.unsplash.com/photo-1482517967863-00e15c9b44be?w=100&h=60&fit=crop',
    label: 'Cozy Fireplace'
  },
  {
    id: 'winter-forest',
    url: 'https://images.unsplash.com/photo-1418985991508-e47386d96a71',
    thumbnail: 'https://images.unsplash.com/photo-1418985991508-e47386d96a71?w=100&h=60&fit=crop',
    label: 'Winter Forest'
  },
  {
    id: 'northern-lights',
    url: 'https://images.unsplash.com/photo-1483347756197-71ef80e95f73',
    thumbnail: 'https://images.unsplash.com/photo-1483347756197-71ef80e95f73?w=100&h=60&fit=crop',
    label: 'Northern Lights'
  },
  {
    id: 'snow-cabin',
    url: 'https://images.unsplash.com/photo-1544427920-c49ccfb85579',
    thumbnail: 'https://images.unsplash.com/photo-1544427920-c49ccfb85579?w=100&h=60&fit=crop',
    label: 'Snow Cabin'
  },
  {
    id: 'reindeer-stable',
    url: 'https://images.unsplash.com/photo-1513297887119-d46091b24bfa',
    thumbnail: 'https://images.unsplash.com/photo-1513297887119-d46091b24bfa?w=100&h=60&fit=crop',
    label: 'Reindeer Stable'
  },
  {
    id: 'gift-workshop',
    url: 'https://images.unsplash.com/photo-1512474932049-78ac69ede12c',
    thumbnail: 'https://images.unsplash.com/photo-1512474932049-78ac69ede12c?w=100&h=60&fit=crop',
    label: 'Gift Workshop'
  },
  {
    id: 'sleigh-launch',
    url: 'https://images.unsplash.com/photo-1513297887119-d46091b24bfa',
    thumbnail: 'https://images.unsplash.com/photo-1513297887119-d46091b24bfa?w=100&h=60&fit=crop',
    label: 'Sleigh Launch'
  }
];