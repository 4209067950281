import React, { useEffect, useRef, useState } from "react";
import { VolumeBars } from "./sound-meter/VolumeBars";

interface SoundMeterProps {
  isListening?: boolean;
  className?: string;
  onSoundLevelChange?: (soundLevel: number) => void; // Add this callback prop
}

export const SoundMeter: React.FC<SoundMeterProps> = ({
  isListening = false,
  className = "",
  onSoundLevelChange,
}) => {
  const [soundLevel, setSoundLevel] = useState(0);
  const [feedback, setFeedback] = useState<string>("Make sure your mic is ready!");
  const [feedbackColor, setFeedbackColor] = useState<string>("text-gray-300");
  const [error, setError] = useState<string>("");

  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const pollingRef = useRef<number | null>(null);

  const startListening = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      audioContextRef.current = new AudioContext();
      await audioContextRef.current.resume();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);

      analyserRef.current.fftSize = 256;
      source.connect(analyserRef.current);

      setError("");
    } catch (err) {
      setError("Please allow microphone access");
      console.error("Error accessing microphone:", err);
    }
  };

  const stopListening = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }

    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    setSoundLevel(0);
    setFeedback("Make sure your mic is ready!");
    setFeedbackColor("text-gray-300");
    onSoundLevelChange?.(0); // Reset sound level
  };

  const pollSoundLevel = () => {
    if (!analyserRef.current) return;

    const dataArray = new Uint8Array(analyserRef.current.frequencyBinCount);
    analyserRef.current.getByteFrequencyData(dataArray);

    const average =
      dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;

    setSoundLevel(average);
    onSoundLevelChange?.(average); // Trigger callback with current sound level

    // Goldilocks Zone Feedback Logic
    if (average < 20) {
      setFeedback("Too quiet, speak louder or adjust your microphone.");
      setFeedbackColor("text-red-500");
    } else if (average >= 20 && average < 57) {
      setFeedback("Sound level is perfect! 🎉");
      setFeedbackColor("text-emerald-500");
    } else if (average >= 57 && average < 70) {
      setFeedback("It's getting a bit loud. Speak softer.");
      setFeedbackColor("text-yellow-500");
    } else {
      setFeedback("Too loud! Please adjust your environment.");
      setFeedbackColor("text-red-500");
    }
  };

  useEffect(() => {
    if (isListening) {
      startListening();
      pollingRef.current = window.setInterval(() => {
        pollSoundLevel();
      }, 100);
    } else {
      stopListening();
    }

    return () => {
      stopListening();
    };
  }, [isListening]);

  return (
    <div
      className={`flex items-center justify-center gap-4 bg-black/20 rounded-lg transition-all duration-300 hover:bg-black/30 ${className}`}
    >
      <div className="p-4 space-y-4">
        {error && <p className="text-red-500 text-xs text-center">{error}</p>}
        {isListening && (
          <>
            <div className="flex flex-col items-center justify-center">
              <VolumeBars soundLevel={soundLevel} maxBars={20} />
              <p
                className={`max-w-[10rem] mt-[1rem] text-xs sm text-center font-medium ${feedbackColor}`}
              >
                {feedback}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};