import React, { useEffect, useRef } from 'react';

interface AudioWaveformProps {
  isActive: boolean;
  isSantaSpeaking?: boolean;
}

export function AudioWaveform({ isActive, isSantaSpeaking = false }: AudioWaveformProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const frameCountRef = useRef(0);

  useEffect(() => {
    if (!canvasRef.current || !isActive) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d')!;
    let animationId: number;

    const draw = () => {
      frameCountRef.current += 1;
      
      // Only update every 15 frames (slows down animation)
      if (frameCountRef.current % 15 === 0) {
        const width = canvas.width;
        const height = canvas.height;
        ctx.clearRect(0, 0, width, height);

        // Set color based on who is speaking
        const color = isSantaSpeaking ? 'rgba(0, 255, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)';
        ctx.fillStyle = color;

        // Generate smoother waveform
        const bars = 12; // Reduced number of bars
        const barWidth = width / bars - 4; // Increased spacing
        
        for (let i = 0; i < bars; i++) {
          const barHeight = Math.random() * (height / 3) + height / 3; // More consistent heights
          const x = i * (barWidth + 4) + 2;
          const y = height / 2 - barHeight / 2;
          
          ctx.beginPath();
          ctx.roundRect(x, y, barWidth, barHeight, 8);
          ctx.fill();
        }
      }

      animationId = requestAnimationFrame(draw);
    };

    draw();
    return () => cancelAnimationFrame(animationId);
  }, [isActive, isSantaSpeaking]);

  return (
    <canvas
      ref={canvasRef}
      width={300}
      height={60}
      className="w-full h-16 opacity-80"
    />
  );
}