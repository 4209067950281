import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause } from 'lucide-react';

interface VideoSegmentSelectorProps {
  videoUrl: string;
  maxDuration: number;
  onSegmentChange: (start: number, end: number) => void;
}

export function VideoSegmentSelector({ videoUrl, maxDuration, onSegmentChange }: VideoSegmentSelectorProps) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [actualDuration, setActualDuration] = useState(0);
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const isDraggingRef = useRef<'start' | 'end' | null>(null);
  console.log("🔴 Do Something", maxDuration)

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleLoadedMetadata = () => {
        const duration = Math.floor(video.duration);
        setActualDuration(duration);
        // Set initial range to first 30s or full duration if shorter
        const initialEnd = Math.min(30, duration);
        setRangeEnd(initialEnd);
        onSegmentChange(0, initialEnd);
      };
      video.addEventListener('loadedmetadata', handleLoadedMetadata);
      return () => video.removeEventListener('loadedmetadata', handleLoadedMetadata);
    }
  }, [onSegmentChange]);

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.currentTime = rangeStart;
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying, rangeStart]);

  const handleTimeUpdate = () => {
    if (videoRef.current && videoRef.current.currentTime >= rangeEnd) {
      videoRef.current.pause();
      videoRef.current.currentTime = rangeStart;
      setIsPlaying(false);
    }
  };

  const handleMouseDown = (e: React.MouseEvent, edge: 'start' | 'end') => {
    e.preventDefault();
    isDraggingRef.current = edge;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleMouseUp);
  };

  const handleTouchMove = (e: TouchEvent) => {
    if (!isDraggingRef.current || !sliderRef.current) return;
    const touch = e.touches[0];
    updateRangeFromPosition(touch.clientX);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDraggingRef.current || !sliderRef.current) return;
    updateRangeFromPosition(e.clientX);
  };

  const updateRangeFromPosition = (clientX: number) => {
    if (!sliderRef.current) return;
    const rect = sliderRef.current.getBoundingClientRect();
    const position = (clientX - rect.left) / rect.width;
    const time = Math.max(0, Math.min(actualDuration, Math.round(position * actualDuration)));

    if (isDraggingRef.current === 'start') {
      const newStart = Math.min(time, rangeEnd - 1);
      setRangeStart(newStart);
      onSegmentChange(newStart, rangeEnd);
      if (videoRef.current) videoRef.current.currentTime = newStart;
    } else {
      const newEnd = Math.max(time, rangeStart + 1);
      const maxEnd = Math.min(newEnd, rangeStart + 30); // Ensure max 30s selection
      setRangeEnd(maxEnd);
      onSegmentChange(rangeStart, maxEnd);
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = null;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleMouseUp);
  };

  const togglePlayback = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="w-full space-y-4">
      {/* Video Preview */}
      <div className="relative rounded-xl overflow-hidden aspect-video bg-black">
        <video
          ref={videoRef}
          src={videoUrl}
          className="w-full h-full object-contain"
          onTimeUpdate={handleTimeUpdate}
          onEnded={() => setIsPlaying(false)}
          playsInline
        />
        <button
          onClick={togglePlayback}
          className="absolute inset-0 flex items-center justify-center bg-black/30 
                   hover:bg-black/40 transition-colors"
        >
          {isPlaying ? (
            <Pause className="w-12 h-12 text-white" />
          ) : (
            <Play className="w-12 h-12 text-white" />
          )}
        </button>
      </div>

      {/* Range Slider */}
      <div className="space-y-2 px-4">
        <div className="flex justify-between text-white/80 text-sm">
          <span>Select 30s Segment</span>
          <span>
            {Math.floor(rangeStart)}s - {Math.ceil(rangeEnd)}s
          </span>
        </div>
        
        <div 
          ref={sliderRef} 
          className="relative h-12 bg-white/10 rounded-lg mt-2"
        >
          {/* Track */}
          <div className="absolute inset-0 bg-white/5 rounded-lg">
            {/* Selected Range */}
            <div
              className="absolute h-full bg-red-500/30 backdrop-blur-sm"
              style={{
                left: `${(rangeStart / actualDuration) * 100}%`,
                width: `${((rangeEnd - rangeStart) / actualDuration) * 100}%`
              }}
            />
          </div>

          {/* Start Handle */}
          <div
            className="absolute top-0 bottom-0 w-4 -ml-2 flex items-center justify-center 
                     cursor-ew-resize touch-none"
            style={{ left: `${(rangeStart / actualDuration) * 100}%` }}
            onMouseDown={(e) => handleMouseDown(e, 'start')}
            onTouchStart={(e) => handleMouseDown(e as any, 'start')}
          >
            <div className="w-1 h-full bg-red-500 rounded-full">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 
                          w-4 h-4 bg-red-500 rounded-full shadow-lg" />
            </div>
          </div>

          {/* End Handle */}
          <div
            className="absolute top-0 bottom-0 w-4 -mr-2 flex items-center justify-center 
                     cursor-ew-resize touch-none"
            style={{ left: `${(rangeEnd / actualDuration) * 100}%` }}
            onMouseDown={(e) => handleMouseDown(e, 'end')}
            onTouchStart={(e) => handleMouseDown(e as any, 'end')}
          >
            <div className="w-1 h-full bg-red-500 rounded-full">
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 
                          w-4 h-4 bg-red-500 rounded-full shadow-lg" />
            </div>
          </div>

          {/* Duration Scale */}
          <div className="absolute -bottom-6 left-0 right-0 flex justify-between text-xs text-white/60">
            <span>0s</span>
            <span>{actualDuration}s</span>
          </div>
        </div>
      </div>
    </div>
  );
}