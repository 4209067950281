import React from "react";

export const TermsPolicy: React.FC = () => {
  return (
    <div className=" min-h-screen py-10 px-4">
      <div className="max-w-4xl mx-auto text-white shadow-md glassmorphism rounded-lg p-6">
        <h1 className="text-2xl font-bold text-gray-200 mb-6">Terms and Conditions</h1>
        <p className="text-sm text-gray-50 mb-4">Last Updated: December 5, 2024</p>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">1. Acceptance of Terms</h2>
          <p className="text-gray-100 leading-relaxed">
            By accessing or using the services, websites, mobile applications, and related offerings
            provided by Shooflyai (including the "Calls with Santa" platform) (collectively, the
            "Services"), you ("User," "you," or "your") acknowledge that you have read, understood,
            and agree to be bound by these Terms and Conditions ("Terms") and our Privacy Policy,
            which is incorporated herein by reference. If you do not agree to these Terms, you must
            not use our Services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">2. Services Provided</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            Shooflyai provides the "Calls with Santa" platform, a digital experience designed to
            emulate interactions with "Santa Claus" through cutting-edge technology. Our Services
            utilize cloud computing, artificial intelligence, and a mobile-friendly user interface
            to create personalized, engaging, and interactive virtual calls. These calls are
            generated by advanced software and do not involve a live Santa actor. Instead, "Santa"
            is a virtual persona created through AI-driven voice technology and other computational
            methods, delivering a festive, family-oriented experience.
          </p>
          <p className="text-gray-100 leading-relaxed">
            You may select from various packages, including one-time calls, extended conversations
            with recordings, and family calls. Complete details regarding features, functionality,
            and pricing are available on our website and may be updated from time to time.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            3. Use of the Services and Third-Party Platforms
          </h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Age Requirement:</strong> The Services are intended for users who have reached
            the age of majority in their jurisdiction. If you are not of legal age, you may only use
            the Services under the supervision and with the consent of a parent or legal guardian
            who agrees to be bound by these Terms.
          </p>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Children’s Privacy:</strong> Our Services may appeal to children under the
            supervision of adults. We do not knowingly collect personal information from children in
            violation of applicable law, including the U.S. Children’s Online Privacy Protection Act
            ("COPPA"). Parents or guardians must review our Privacy Policy for detailed information
            on how we handle children’s data and must provide any required consents.
          </p>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Territorial Restrictions:</strong> The Services may not be available in all
            locations. It is your responsibility to ensure that your use of the Services is lawful
            in your jurisdiction. We reserve the right to restrict access to the Services at any
            time.
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Compliance with Platform Policies:</strong> If you access our Services through
            or in connection with third-party platforms such as Meta (Facebook, Instagram) or
            TikTok, you agree to comply with their respective terms of service, community
            guidelines, and other applicable policies. Shooflyai is not endorsed, sponsored by, or
            affiliated with Meta or TikTok. Your use of the Services on or through these platforms
            is at your own risk and must comply with all third-party platform policies and
            applicable laws.
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            4. Account Registration and Security
          </h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Account Creation:</strong> Certain features may require registration and the
            creation of an account. You agree to provide accurate, current, and complete information
            and to update such information as needed.
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Security:</strong> You are responsible for maintaining the confidentiality of
            your login credentials and for all activities under your account. You must notify us
            immediately of any unauthorized use of your account.
          </p>
        </section>

        {/* Section 5 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">5. Prohibited Activities</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            You agree not to use the Services in any manner that:
          </p>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>Violates any applicable law, regulation, or these Terms;</li>
            <li>Infringes upon the rights of others, including intellectual property, privacy, or publicity rights;</li>
            <li>Involves the distribution of harmful software, malware, or any other disruptive code;</li>
            <li>
              Attempts to circumvent or interfere with our security measures, access controls, or
              authentication systems;
            </li>
            <li>
              Disrupts or negatively affects the integrity, performance, or availability of the
              Services or related systems;
            </li>
            <li>
              Violates the community standards or content guidelines of any third-party platform
              through which you access our Services.
            </li>
          </ul>
        </section>

        {/* Section 6 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">6. Privacy Policy</h2>
          <p className="text-gray-100 leading-relaxed">
            Our Privacy Policy details how we collect, use, store, and disclose your personal
            information. By using the Services, you acknowledge that you have read, understood, and
            consent to the practices described therein. If you do not agree with the Privacy Policy,
            you must discontinue use of the Services.
          </p>
        </section>

        {/* Section 7 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">7. Fees, Payments, and Refunds</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Pricing:</strong> All prices, fees, and charges ("Fees") are listed on our
            website and may change without notice.
          </p>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Payment:</strong> Payments are processed through secure, third-party payment
            providers. By providing payment information, you represent and warrant that you are
            authorized to use the chosen payment method and that all information is accurate.
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>No Refunds:</strong> Due to the highly personalized and time-sensitive nature of
            our Services, all sales are final. Except as required by applicable law, no refunds or
            credits will be provided after a Service has been delivered, in whole or in part.
          </p>
        </section>

        {/* Section 8 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            8. Intellectual Property Rights
          </h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Ownership:</strong> All trademarks, service marks, logos, content, text,
            graphics, images, software, and other intellectual property ("Company IP") provided
            through the Services are owned by Shooflyai or our licensors.
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Limited License:</strong> We grant you a limited, non-exclusive,
            non-transferable, revocable license to access and use the Services for personal,
            non-commercial purposes in accordance with these Terms. Any unauthorized use of Company
            IP is prohibited.
          </p>
        </section>

        {/* Section 9 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">9. User Content</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            If you submit or upload any content (including messages, audio, or images) to the
            Services, you grant Shooflyai a worldwide, non-exclusive, royalty-free, fully paid-up,
            transferable, sublicensable, perpetual, and irrevocable license to use, reproduce,
            distribute, modify, create derivative works of, publicly display, and otherwise exploit
            such content for the operation and enhancement of the Services.
          </p>
          <p className="text-gray-100 leading-relaxed">
            You represent and warrant that your content does not violate the rights of any third
            party, comply with all applicable laws, and does not violate these Terms or the policies
            of any third-party platform through which you access our Services.
          </p>
        </section>

        {/* Section 10 */}
        <section>
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            10. Disclaimer of Warranties
          </h2>
          <p className="text-gray-100 leading-relaxed">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES ARE PROVIDED "AS IS" AND "AS
            AVAILABLE," WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
            INCLUDING BUT NOT LIMITED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            NON-INFRINGEMENT, SECURITY, ACCURACY, OR QUIET ENJOYMENT. SHOOfLYAI DOES NOT WARRANT
            THAT THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE, OR SECURE, OR THAT ANY DEFECTS WILL
            BE CORRECTED.
          </p>
        </section>
        {/* Section 11 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            11. Limitation of Liability
          </h2>
          <p className="text-gray-100 leading-relaxed">
            TO THE FULLEST EXTENT PERMITTED BY LAW, SHOOfLYAI, ITS AFFILIATES, DIRECTORS, OFFICERS,
            EMPLOYEES, AGENTS, LICENSORS, OR SERVICE PROVIDERS SHALL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING LOST
            PROFITS, LOST REVENUE, LOST DATA, PERSONAL INJURY, PROPERTY DAMAGE, OR OTHER LOSSES,
            ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICES, REGARDLESS OF
            THE THEORY OF LIABILITY. WHERE LIABILITY CANNOT BE EXCLUDED, IT IS LIMITED TO THE
            EXTENT PERMITTED BY LAW.
          </p>
        </section>

        {/* Section 12 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">12. Indemnification</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            You agree to defend, indemnify, and hold harmless Shooflyai, its affiliates, and its and
            their respective directors, officers, employees, agents, licensors, and service
            providers from any claims, liabilities, damages, losses, costs, and expenses (including
            reasonable attorneys’ fees) arising out of or related to:
          </p>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>Your use or misuse of the Services;</li>
            <li>Your violation of these Terms or any applicable law;</li>
            <li>
              Your infringement of any third-party right, including intellectual property or privacy
              rights.
            </li>
          </ul>
        </section>

        {/* Section 13 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            13. Modifications to Services and Terms
          </h2>
          <p className="text-gray-100 leading-relaxed">
            We may, at our sole discretion, modify, suspend, or discontinue the Services (in whole
            or in part) at any time. We may also revise these Terms at any time by posting updated
            Terms on our website. Your continued use of the Services after the posting of any
            changes constitutes acceptance of those changes.
          </p>
        </section>

        {/* Section 14 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            14. Governing Law and Dispute Resolution
          </h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            These Terms and any dispute arising from or relating to these Terms or the Services
            shall be governed by and construed in accordance with the laws of the State of Georgia,
            United States, without regard to its conflicts of law principles.
          </p>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Arbitration:</strong> Any dispute arising out of or relating to these Terms or
            the Services shall be resolved by binding arbitration in English in Fulton County,
            Georgia, U.S.A., before a single neutral arbitrator. Judgment on the arbitrator’s award
            may be entered in any court having jurisdiction.
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Class Action Waiver:</strong> You agree to resolve any disputes on an individual
            basis and waive the right to participate in class actions, class arbitrations, or
            representative actions.
          </p>
        </section>

        {/* Section 15 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            15. Severability and Waiver
          </h2>
          <p className="text-gray-100 leading-relaxed">
            If any provision of these Terms is deemed unlawful, void, or unenforceable, that
            provision shall be severable and shall not affect the validity or enforceability of any
            remaining provisions. Shooflyai’s failure to enforce any right or provision shall not be
            deemed a waiver.
          </p>
        </section>

        {/* Section 16 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">16. Entire Agreement</h2>
          <p className="text-gray-100 leading-relaxed">
            These Terms, along with our Privacy Policy and any other legal notices or agreements
            published by us, constitute the entire agreement between you and Shooflyai regarding
            your use of the Services, superseding any prior agreements.
          </p>
        </section>

        {/* Section 17 */}
        <section>
          <h2 className="text-xl font-semibold text-gray-100 mb-4">17. Contact Information</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            For questions, comments, or concerns regarding these Terms or the Services, please
            contact us at:
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Email:</strong> santa.support@shooflyai.com
          </p>
        </section>
        {/* Section 11 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            11. Limitation of Liability
          </h2>
          <p className="text-gray-100 leading-relaxed">
            TO THE FULLEST EXTENT PERMITTED BY LAW, SHOOfLYAI, ITS AFFILIATES, DIRECTORS, OFFICERS,
            EMPLOYEES, AGENTS, LICENSORS, OR SERVICE PROVIDERS SHALL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING LOST
            PROFITS, LOST REVENUE, LOST DATA, PERSONAL INJURY, PROPERTY DAMAGE, OR OTHER LOSSES,
            ARISING FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICES, REGARDLESS OF
            THE THEORY OF LIABILITY. WHERE LIABILITY CANNOT BE EXCLUDED, IT IS LIMITED TO THE
            EXTENT PERMITTED BY LAW.
          </p>
        </section>

        {/* Section 12 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">12. Indemnification</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            You agree to defend, indemnify, and hold harmless Shooflyai, its affiliates, and its and
            their respective directors, officers, employees, agents, licensors, and service
            providers from any claims, liabilities, damages, losses, costs, and expenses (including
            reasonable attorneys’ fees) arising out of or related to:
          </p>
          <ul className="list-disc list-inside text-gray-100 space-y-2">
            <li>Your use or misuse of the Services;</li>
            <li>Your violation of these Terms or any applicable law;</li>
            <li>
              Your infringement of any third-party right, including intellectual property or privacy
              rights.
            </li>
          </ul>
        </section>

        {/* Section 13 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            13. Modifications to Services and Terms
          </h2>
          <p className="text-gray-100 leading-relaxed">
            We may, at our sole discretion, modify, suspend, or discontinue the Services (in whole
            or in part) at any time. We may also revise these Terms at any time by posting updated
            Terms on our website. Your continued use of the Services after the posting of any
            changes constitutes acceptance of those changes.
          </p>
        </section>

        {/* Section 14 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            14. Governing Law and Dispute Resolution
          </h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            These Terms and any dispute arising from or relating to these Terms or the Services
            shall be governed by and construed in accordance with the laws of the State of Georgia,
            United States, without regard to its conflicts of law principles.
          </p>
          <p className="text-gray-100 leading-relaxed mb-4">
            <strong>Arbitration:</strong> Any dispute arising out of or relating to these Terms or
            the Services shall be resolved by binding arbitration in English in Fulton County,
            Georgia, U.S.A., before a single neutral arbitrator. Judgment on the arbitrator’s award
            may be entered in any court having jurisdiction.
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Class Action Waiver:</strong> You agree to resolve any disputes on an individual
            basis and waive the right to participate in class actions, class arbitrations, or
            representative actions.
          </p>
        </section>

        {/* Section 15 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">
            15. Severability and Waiver
          </h2>
          <p className="text-gray-100 leading-relaxed">
            If any provision of these Terms is deemed unlawful, void, or unenforceable, that
            provision shall be severable and shall not affect the validity or enforceability of any
            remaining provisions. Shooflyai’s failure to enforce any right or provision shall not be
            deemed a waiver.
          </p>
        </section>

        {/* Section 16 */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold text-gray-100 mb-4">16. Entire Agreement</h2>
          <p className="text-gray-100 leading-relaxed">
            These Terms, along with our Privacy Policy and any other legal notices or agreements
            published by us, constitute the entire agreement between you and Shooflyai regarding
            your use of the Services, superseding any prior agreements.
          </p>
        </section>

        {/* Section 17 */}
        <section>
          <h2 className="text-xl font-semibold text-gray-100 mb-4">17. Contact Information</h2>
          <p className="text-gray-100 leading-relaxed mb-4">
            For questions, comments, or concerns regarding these Terms or the Services, please
            contact us at:
          </p>
          <p className="text-gray-100 leading-relaxed">
            <strong>Email:</strong> santa.support@shooflyai.com
          </p>
        </section>

      </div>
    </div>
  );
};