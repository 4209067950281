import React, { useState } from 'react';
import { X } from 'lucide-react';
import { SupportForm } from './SupportForm';

interface SupportModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContactSupport: (email: string) => void;
  timeLeft: number;
}

export function SupportModal({ isOpen, onClose, onContactSupport, timeLeft }: SupportModalProps) {
  const [showForm, setShowForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  
  if (!isOpen) return null;

  const showOnlyForm = timeLeft <= 60;

  const handleContactElves = () => {
    setShowForm(true);
  };

  const handleSubmit = (email: string) => {
    onContactSupport(email);
    setShowSuccess(true);
  };

  const handleReturnHome = () => {
    window.location.reload();
  };

  if (showSuccess) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div className="absolute inset-0 bg-black/80 backdrop-blur-md" />
        <div className="relative bg-gradient-to-b from-red-900 to-red-950 rounded-2xl p-8 w-full max-w-sm mx-auto shadow-2xl border border-red-500/20">
          <div className="text-center space-y-4">
            <h3 className="text-2xl font-bold text-white">🎄 Message Sent!</h3>
            <p className="text-white/90">Our Chief Technology Elf will be in touch soon!</p>
            <button
              onClick={handleReturnHome}
              className="w-full px-4 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 
                       transition-all transform active:scale-98 text-sm"
            >
              Return to Home Screen
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (showForm || showOnlyForm) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
        <div className="absolute inset-0 bg-black/80 backdrop-blur-md" onClick={onClose} />
        <div className="relative bg-gradient-to-b from-red-900 to-red-950 rounded-2xl p-8 w-full max-w-sm mx-auto shadow-2xl border border-red-500/20">
          <button 
            onClick={onClose}
            className="absolute top-4 right-4 w-8 h-8 bg-red-950 text-white/70 hover:text-white 
                     rounded-full flex items-center justify-center transition-colors border border-red-500/20"
          >
            <X className="w-4 h-4" />
          </button>

          <div className="text-center space-y-4">
            <h3 className="text-2xl font-bold text-white">
              🎄 Contact Our Elves
            </h3>
            <SupportForm onSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="absolute inset-0 bg-black/80 backdrop-blur-md" onClick={onClose} />
      <div className="relative bg-gradient-to-b from-red-900 to-red-950 rounded-2xl p-8 w-full max-w-sm mx-auto shadow-2xl border border-red-500/20">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 w-8 h-8 bg-red-950 text-white/70 hover:text-white 
                   rounded-full flex items-center justify-center transition-colors border border-red-500/20"
        >
          <X className="w-4 h-4" />
        </button>

        <div className="text-center space-y-4">
          <h3 className="text-2xl font-bold text-white">
            ⚠️ Hold Your Reindeer!
          </h3>
          
          <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4">
            <p className="text-white/90 text-sm">
              If you contact support now, your current call session will end and you'll need to speak with our elves before getting back in line to talk to Santa.
            </p>
          </div>
          
          <div className="flex flex-col gap-3 mt-6">
            <button
              onClick={handleContactElves}
              className="w-full px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 
                       transition-all transform active:scale-98 shadow-lg hover:shadow-red-500/25 
                       text-sm"
            >
              Contact Elves for Help
            </button>
            {timeLeft > 60 && (
              <button
                onClick={onClose}
                className="w-full px-4 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 
                         transition-all transform active:scale-98 backdrop-blur-sm text-sm"
              >
                🎅 Call Santa 📞
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}