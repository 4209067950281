import ReactGA from "react-ga4";

/**
 * Initializes Google Analytics with the provided measurement ID.
 * 
 * @param measurementId - The Google Analytics 4 measurement ID (e.g., G-XXXXXXXXXX).
 */
export const initGoogleAnalytics = (measurementId: string): void => {
  if (!measurementId) {
    console.error("Google Analytics measurement ID is required.");
    return;
  }

  ReactGA.initialize(measurementId);
};