import React, { useEffect } from 'react';

declare global {
  interface Window {
    fbq?: (...args: any[]) => void;
    _fbq?: unknown;
  }
}

export const AnalyticsMeta: React.FC = () => {
  useEffect(() => {
    // Check if `fbq` is already initialized
    if (typeof window.fbq !== 'function') {
      // Initialize Meta Pixel
      (function (
        f: any,
        b: Document,
        e: string,
        v: string,
        n?: any,
        t?: HTMLScriptElement,
        s?: HTMLScriptElement | null
      ) {
        if (f.fbq) return;
        n = f.fbq = function (...args: any[]) {
          (n.callMethod
            ? n.callMethod.apply(n, args)
            : n.queue.push(args));
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = true;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e) as HTMLScriptElement;
        t.async = true;
        t.src = v;
        s = b.getElementsByTagName(e)[0] as HTMLScriptElement;
        s?.parentNode?.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js'
      );
    }

    // Initialize with your Pixel ID
    if (typeof window.fbq === 'function') {
      window.fbq('init', '615202324405549');
      window.fbq('track', 'PageView');
    }
  }, []);

  return (
    <noscript>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=615202324405549&ev=PageView&noscript=1"
        alt=""
      />
    </noscript>
  );
};