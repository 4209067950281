import React from 'react';
import { Clock } from 'lucide-react';
import { formatDuration } from '../../utils/time';

interface EndCallModalProps {
  isOpen: boolean;
  timeLeft: number;
  onClose: () => void;
  onConfirm: () => void;
}

export function EndCallModal({ isOpen, timeLeft, onClose, onConfirm }: EndCallModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
      <div className="absolute inset-0 bg-black/80 backdrop-blur-md" onClick={onClose} />
      <div className="relative bg-gradient-to-b from-red-900 to-red-950 rounded-2xl p-6 w-full max-w-sm mx-auto shadow-2xl border border-red-500/20">
        <div className="text-center space-y-4">
          <h3 className="text-2xl font-bold text-white">
            🎅 End Call Early?
          </h3>
          
          <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4 flex items-center justify-center gap-2">
            <Clock className="w-5 h-5 text-red-400" />
            <p className="text-white/90 text-sm">
              {formatDuration(timeLeft)} left with Santa
            </p>
          </div>
          
          <div className="flex flex-col gap-3 mt-6">
            <button
              onClick={onConfirm}
              className="w-full px-4 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 
                       transition-all transform active:scale-98 text-sm"
            >
              Yes, Santa Was Great! 🎄
            </button>
            <button
              onClick={onClose}
              className="w-full px-4 py-3 bg-white/10 text-white rounded-lg hover:bg-white/20 
                       transition-all transform active:scale-98 backdrop-blur-sm text-sm"
            >
              Back to Santa 🎅 📞
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}