import React, { useState, useEffect } from 'react';
import { getSessionById } from '../../utils/endpoints';

interface LoadingScreenProps {
  onLoadingComplete: () => void;
}

export function LoadingScreen({ onLoadingComplete }: LoadingScreenProps) {
  const [hasPaid, setHasPaid] = useState(false);

  const getAndHandleSession = async () => {
    // Extract the session_id from the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const session_id = urlParams.get('session_id');

    if (session_id) {
      try {
        // Store the session_id in localStorage
        localStorage.setItem('session_id', session_id);

        // Fetch session data using the provided endpoint function
        const sessionData = await getSessionById(session_id);
        if (sessionData) {
          console.log('Session Data:', sessionData);

          // Check the session's payment status and update state
          if (sessionData.data.status === 'paid') {
            setHasPaid(true);
          }
        } else {
          console.warn('Session not found or failed to fetch.');
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    } else {
      console.warn('No session_id found in URL.');
    }
  };

  useEffect(() => {
    getAndHandleSession();
  }, []);

  useEffect(() => {
    if (hasPaid) {
      // Redirect to home screen
      window.location.href = '/';
    } else {
      // Trigger loading complete function
      onLoadingComplete();
    }
  }, [hasPaid, onLoadingComplete]);

  return (
    <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-gradient-to-b from-red-900 to-red-950">
      {/* Snow effect */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-0 animate-snowfall opacity-30">
          <div
            className="absolute inset-0 bg-repeat bg-[length:700px_700px]"
            style={{
              backgroundImage: `radial-gradient(circle at center, white 0.15%, transparent 0.5%)`,
            }}
          />
        </div>
      </div>

      {/* Loading animation */}
      <div className="relative">
        <div className="w-24 h-24 border-8 border-red-300/20 border-t-red-500 rounded-full animate-spin" />
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-4xl">🎄</span>
        </div>
      </div>

      <p className="mt-8 text-xl font-medium text-white text-center px-4">
        Connecting to the North Pole...
      </p>
    </div>
  );
}