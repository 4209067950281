import React, { useState } from 'react';
import { AlertCircle, PhoneCall } from 'lucide-react';
import axios from 'axios';
import { getOrdinalNumber } from './queueUtils';

interface QueueResponse {
  version: string;
  position: number;
}

export function CallQueue() {
  const [position, setPosition] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [callCount, setCallCount] = useState(0);
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const fetchPosition = async () => {
    if (callCount >= 5) {
      setPosition(0);
      return;
    }

    try {
      setLoading(true);
      const { data } = await axios.get<QueueResponse>(`${BASE_URL}/v1/webhooks/queue`);
      setPosition(data.position);
      setCallCount(prev => prev + 1);
      setError(null);
    } catch (err) {
      setError(axios.isAxiosError(err) 
        ? err.response?.data?.message || err.message 
        : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch when component mounts
  React.useEffect(() => {
    const pollQueue = async () => {
      while (true) {
        await fetchPosition();
        if (callCount >= 1) break;
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    };

    pollQueue();
  }, []);

  if (loading && position === null) {
    return (
      <div className="text-center text-gray-600">
        Loading queue position...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600">
        Error: {error}
      </div>
    );
  }

  if (position === null) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="relative py-3 w-full" style={{
            animation: 'breathing 2.5s infinite ease-in-out',
            transformOrigin: 'center',
          }}>
        <div className="absolute inset-0 bg-gradient-to-r from-red-500 via-green-500 to-red-500 animate-gradient-x rounded-lg" />
        <div className="absolute inset-[2px] bg-gradient-to-r from-red-900 via-green-900 to-red-900 backdrop-blur-sm rounded-lg" />
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="flex items-center justify-center gap-3 text-white">
            <AlertCircle className="w-5 h-5 text-red-500 animate-pulse" />
            <p className="text-[12px] md:text-base font-medium">
              {position === 0 
                ? "Santa is ready to talk now, press the answer button below!" 
                : `You are ${getOrdinalNumber(position)} in line to talk to santa!`}
            </p>
          </div>
        </div>
      </div>
      
      {/* {position === 0 && (
        <button 
          onClick={() => console.log('Answer call')} 
          className="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white px-6 py-3 rounded-lg transition-colors shadow-lg"
        >
          <PhoneCall className="w-5 h-5" />
          <span>Answer Call</span>
        </button>
      )} */}
    </div>
  );
}