import React, { useRef, useEffect } from 'react';

interface VideoPreviewProps {
  stream: MediaStream | null;
  isSpeaking?: boolean;
  isSantaSpeaking?: boolean;
}

export function VideoPreview({ stream, isSpeaking = false, isSantaSpeaking = false }: VideoPreviewProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) return null;

  const borderColor = isSpeaking ? 'border-red-500' : isSantaSpeaking ? 'border-green-500' : 'border-white/20';
  const glowEffect = isSpeaking ? 'shadow-red-500/50' : isSantaSpeaking ? 'shadow-green-500/50' : 'shadow-white/20';

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      muted
      className={`w-full h-full object-cover ${borderColor} ${glowEffect}`}
    />
  );
}