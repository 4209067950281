import React, { useState, useCallback, useEffect, useRef }  from 'react';
import { Background } from '../components/pro/Background';
import { BackgroundCarousel } from '../components/pro/BackgroundCarousel';
import { LoadingScreen } from '../components/pro/LoadingScreen';
import { StartCallPrompt } from '../components/pro/StartCallPrompt';
import { Controls } from '../components/pro/Controls';
import { CallInfo } from '../components/pro/CallInfo';
import { AudioWaveform } from '../components/pro/AudioWaveform';
import { VideoPreview } from '../components/pro/VideoPreview';
import { SupportModal } from '../components/pro/SupportModal';
import { RecordingModal } from '../components/pro/RecordingModal';
import { EndCallModal } from '../components/pro/EndCallModal';
// import { PostCallReview } from '../components/pro/PostCallReview';
import { useMediaRecorder } from '../hooks/pro/useMediaRecorder';
import { useAmbientSound } from '../hooks/pro/useAmbientSound';
import { useCountdownTimer } from '../hooks/pro/useCountdownTimer';
import { BACKGROUNDS } from '../types/pro/backgrounds';
//api call
import { BLOCKS } from '../types/pro/ai_block'
import { updateSessionData, getSessionById} from '../utils/endpoints';

import { formatDuration } from '../utils/time';
import { Video, VideoOff, PhoneOff } from 'lucide-react';
import { Conversation } from "@11labs/client"; // Import the Conversation class
import { Signal, Clock } from 'lucide-react';
import { sendTikTokPixelData } from '../utils/endpoints.ts'
import { trackPurchase } from '../utils/trackPurchase.ts'

export function SantaCallPagePro() {
  const [isLoading, setIsLoading] = useState(true);
  const [isCallActive, setIsCallActive] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [showRecordingModal, setShowRecordingModal] = useState(false);
  const [showSupportModal, setShowSupportModal] = useState(false);
  const [showEndCallModal, setShowEndCallModal] = useState(false);
  const [aiBlock, setAIBlock] = useState('HIKMybnhQi2KD0DfCTQs');
  const [selectedBackground, setSelectedBackground] = useState('');
  const [isVideoEnabled, setIsVideoEnabled] = useState(false);
  const [isSantaSpeaking, setIsSantaSpeaking] = useState(false);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  const { timeLeft, startTimer, stopTimer, resetTimer } = useCountdownTimer(180); //needs to be 180 seconds
  const { stream, startRecording, stopRecording, recordedChunks } = useMediaRecorder();
  useAmbientSound(isMuted);

  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const [agentStatus, setAgentStatus] = useState("Idle");

  const conversationRef = useRef<Conversation | null>(null); // Ref for the conversation instance

  const handleStartCall = useCallback(() => {
    setShowRecordingModal(true);
    setIsCallActive(true);
  }, []);

  const getSessionData = async () => {
    // Extract the session_id from the URL parameters
    const urlParams = new URLSearchParams(window.location.search);
    const session_id = urlParams.get('session_id');

    if (session_id) {
      try {
        // Store the session_id in localStorage
        localStorage.setItem('session_id', session_id);
        const paymentValue = 10.00;
        const currency = 'USD';
        trackPurchase(paymentValue, currency);
        // Fetch session data using the provided endpoint function
        const sessionData = await getSessionById(session_id);
        if (sessionData) {
          console.log('Session Data:', sessionData);
          return sessionData.data.queue_block
        } else {
          console.warn('Session not found or failed to fetch.');
          return 'a'
        }
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    } else {
      console.warn('No session_id found in URL.');
    }
  };

  const handleUpdateSession = async (action: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    const session_id = urlParams.get('session_id');
  
    if (!session_id) {
      console.error('🚨 Session ID is missing in the URL');
      return; // Exit early if session_id is null
    }
  
    const payload = { session_id, action }; // Ensure session_id is a string
    try {
      const response = await updateSessionData(payload);
      console.log("response", response)
    } catch (error) {
      console.error('Error updating session:', error);
    }
  };

  // starts the audio call
  const handleAudioOnly = useCallback(async () => {
    setShowRecordingModal(false);
    setIsMuted(false);
  
    try {
      // Get the queue_block from session data
      const queue_block = await getSessionData();
      console.log("queue_block", queue_block);
  
      // Validate and type-cast the queue_block
      const validQueueBlock = queue_block?.toUpperCase() as keyof typeof BLOCKS;
  
      if (validQueueBlock && validQueueBlock in BLOCKS) {
        // Map the queue_block to the corresponding value in BLOCKS
        const ai_block = BLOCKS[validQueueBlock] || BLOCKS.A; // Fallback to BLOCKS.A if not found
        console.log("ai_block", ai_block);
        setAIBlock(ai_block);
        // Update the session and proceed
        await handleUpdateSession("call_active");
        await startConversation(ai_block);
      } else {
        console.warn("Using default block: BLOCKS.A");
        setAIBlock(BLOCKS.A); // Default block if no data returned
        // Update the session and proceed
        await handleUpdateSession("call_active");
        await startConversation(aiBlock);
      }
  
      startTimer();
    } catch (error) {
      console.error("Error in handleAudioOnly:", error);
    }
  }, [setShowRecordingModal, setIsMuted, getSessionData, handleUpdateSession, startTimer]);

  const handleEndCall = useCallback(async () => {
    setShowEndCallModal(true);
    stopTimer();
  }, [stopTimer]);

  const handleEndCallModal = useCallback(async () => {
    setShowEndCallModal(true);
  }, []);

  const stopMicrophone = () => {
    console.log("stopping mic")
    console.log("mediaStreamRef.current", mediaStreamRef.current)
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
      console.log("Microphone access stopped");
    }
  };


  const handleConfirmEndCall = useCallback(async () => {
    setShowEndCallModal(true);
    // stopRecording();
    setIsCallActive(true);
    setShowEndCallModal(true);
    stopMicrophone(); // Stop microphone access
    // await stopConversation()
    stopTimer();
    setShowEndCallModal(true);
    handleUpdateSession("call_finished")
    window.open('/end', '_self')
  }, [stopRecording, stopTimer]);

  const handleVideoToggle = useCallback(() => {
    setIsVideoEnabled(prev => !prev);
  }, []);

  const handleSupportRequest = useCallback((email: string) => {
    console.log('Support request:', email);
    setShowSupportModal(false);
    window.location.reload();
  }, []);
  
  const startConversation = async (agentId: string) => {
    console.log("aiBlock", agentId)
    try {
      // Request microphone permission
      await navigator.mediaDevices.getUserMedia({ audio: true });

      console.log("Attempting to start the conversation...");

      // Start the conversation
      const conversation = await Conversation.startSession({
        agentId: agentId, // Replace with your agent ID
        onConnect: () => {
          console.log("Conversation started.");
          setConnectionStatus("Connected");
        },
        onDisconnect: () => {
          console.log("Conversation stopped.");
          setConnectionStatus("Disconnected");
        },
        onError: (error) => {
          console.error("Error:", error);
        },
        onModeChange: (mode) => {
          console.log(`Agent is now ${mode.mode === "speaking" ? "Speaking" : "Listening"}`);
          setAgentStatus(mode.mode === "speaking" ? "Speaking" : "Listening");
        },
      });

      conversationRef.current = conversation;
    } catch (error) {
      console.error("Failed to start conversation:", error);
    }
  };

  const stopConversation = async () => {
    if (conversationRef.current) {
      console.log("Stopping the conversation...");
      await conversationRef.current.endSession();
      conversationRef.current = null;
      setConnectionStatus("Disconnected");
      setAgentStatus("Idle");
      // setShowPostCall(true); // Show PostCallEngagement when manually stopped
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {

      handleUpdateSession("call_finished")
      handleEndCall();
    }
  }, [timeLeft, handleEndCall]);

  useEffect(() => {
    // Call the TikTok Pixel data function on component mount
    const sendPixelData = async () => {
      try {
        await sendTikTokPixelData();
        console.log('TikTok Pixel data sent successfully.');
      } catch (error) {
        console.error('Failed to send TikTok Pixel data:', error);
      }
    };

    sendPixelData();
  }, []);

  const ActiveCallScreen = () => (
    <div className="relative flex flex-col min-h-screen bg-red-950">
      {/* Background Image - Only shown when selected */}
      {selectedBackground && (
        <>
          <div 
            className="absolute inset-0 bg-cover bg-center opacity-35 transition-all duration-300"
            style={{ backgroundImage: `url(${selectedBackground})` }}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/50 via-transparent to-black/50" />
        </>
      )}

      {/* Header Section */}
      <div className="fixed top-[0%] left-0 right-0 z-30">
        <CallInfo duration={formatDuration(timeLeft)} />
      </div>

      {/* Background Carousel - Moved down 7px */}
      <div className="fixed top-[calc(2%+105px)] left-0 right-0 z-20">
        <BackgroundCarousel onSelect={setSelectedBackground} />
      </div>

      {/* Main Content Area */}
      <div className="flex-1 flex flex-col items-center justify-center z-10 relative">
        {/* Video Preview - Adjusted size and position */}
        {isVideoEnabled && (
          <div className="fixed top-[calc(2%+200px)] left-1/2 transform -translate-x-1/2 
                         w-[24rem] h-[27rem] rounded-2xl overflow-hidden border-2 
                         border-white/20 shadow-lg">
            <VideoPreview 
              stream={stream}
              isSpeaking={!isMuted}
              isSantaSpeaking={isSantaSpeaking}
            />
          </div>
        )}
        
        {/* Audio Waveform */}
        <div className={`w-full max-w-sm mx-auto px-4 ${
          isVideoEnabled 
            ? 'fixed top-[calc(2%+570px)]' 
            : 'fixed top-1/2 transform -translate-y-1/2'
        }`}>
          <AudioWaveform 
            isActive={!isMuted} 
            isSantaSpeaking={isSantaSpeaking}
          />

          {/* timer */}
          <div className={`absolute ${isVideoEnabled ? "-bottom-[5rem]" : "-bottom-[10rem]"} centered-axis-x  duration-300 transition flex items-center justify-center space-x-2 text-white`}>
              <Clock className="w-4 h-4" />
              <span className="text-4xl">{formatDuration(timeLeft)}</span>
          </div>
        </div>
      </div>

      {/* Bottom Controls Section */}
      <div className="fixed bottom-0 left-0 right-0 z-30">
        <div className="bg-gradient-to-t from-black/90 to-transparent pb-6">
          {/* Support Button */}
          <div className="w-full max-w-sm mx-auto px-4 mb-6">
            <button
              onClick={() => setShowSupportModal(true)}
              className="w-full py-3 px-6 bg-black/40 backdrop-blur-sm rounded-xl 
                       border border-white/10 text-white/90 text-sm 
                       hover:bg-black/50 transition-colors"
            >
              Need help? Contact our elves ❄️ 🔧
            </button>
          </div>

          {/* Call Controls */}
          <div className="w-full max-w-sm mx-auto px-4 pb-4 flex items-center justify-center space-x-8">

            {/*close video*/}
            {/* <button
              onClick={handleVideoToggle}
              className={`p-5 rounded-full ${
                isVideoEnabled ? 'bg-green-600' : 'bg-red-500'
              } transition-all transform active:scale-95 shadow-lg hover:shadow-xl`} >
              {isVideoEnabled ? 
                <Video className="w-6 h-6 text-white" /> : 
                <VideoOff className="w-6 h-6 text-white" />
              }
            </button> */}
            
            {/*Call ends*/}
            <button
              onClick={handleEndCallModal}
              className="p-5 rounded-full bg-red-600 transition-all transform 
                       active:scale-95 shadow-lg hover:shadow-xl">
              <PhoneOff className="w-6 h-6 text-white" />
            </button>

          </div>
        </div>
      </div>

      {/* Modals */}
      <RecordingModal
        isOpen={showRecordingModal}
        onClose={() => {
          setShowRecordingModal(false);
          setIsCallActive(false); // Stops the call if the modal is closed
        }}
        onConfirm={() => {
          console.log("handleAudioOnly");
          handleAudioOnly()
        }}
        onCheckMicrophone={() => {
          // Handle the microphone check logic
          console.log("User wants to check their microphone.");
          // Add your microphone test logic here
        }}
      />
      {/* <RecordingModal
        isOpen={showRecordingModal}
        onClose={() => {
          setShowRecordingModal(false);
          setIsCallActive(false);
        }}
        // onStartRecording={handleStartRecording}
        onConfirm={handleAudioOnly}
      /> */}

      <SupportModal
        isOpen={showSupportModal}
        onClose={() => setShowSupportModal(false)}
        onContactSupport={handleSupportRequest}
        timeLeft={timeLeft}
      />

      <EndCallModal
        isOpen={showEndCallModal}
        timeLeft={timeLeft}
        onClose={() => setShowEndCallModal(false)}
        onConfirm={handleConfirmEndCall}
      />
    </div>
  );

  return (
    <>
      {isLoading ? (
        <LoadingScreen onLoadingComplete={() => setIsLoading(false)} />
      ) : (
        <>
          {!isCallActive ? (
            <StartCallPrompt onClick={handleStartCall} />
          ) : (
            <ActiveCallScreen />
          )}
        </>
      )}
    </>
  );
}