import React, { useEffect, useState } from 'react';
import { calculateProgress, getProgressColor } from './progressUtils';

interface ProgressBarProps {
  duration?: number; // in seconds
  height?: number; // in pixels
}

export const SnowTimer: React.FC<ProgressBarProps> = ({
  duration = 180,
  height = 40,
}) => {
  const [startTime, setStartTime] = useState<number>(Date.now());
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      const elapsed = (Date.now() - startTime) / 1000;
      const newProgress = calculateProgress(elapsed, duration);
      
      if (elapsed >= duration) {
        clearInterval(timer);
        setProgress(100);
      } else {
        setProgress(newProgress);
      }
    }, 50);

    return () => clearInterval(timer);
  }, [startTime, duration]);

  const progressColor = getProgressColor(progress, duration);

  return (
    <div className="w-full max-w-md">
      <div 
        className="relative w-full bg-gray-200 rounded-full overflow-hidden"
        style={{ height: `${height}px` }}
      >
        <div
          className="absolute top-0 left-0 h-full transition-all duration-300 ease-linear rounded-full"
          style={{
            width: `${progress}%`,
            backgroundColor: progressColor,
          }}
        />
        <div
          className="absolute top-0 h-full transition-all duration-300 ease-linear flex items-center justify-center"
          style={{
            left: `${progress}%`,
            transform: 'translateX(-50%)',
          }}
        >
          <span 
            className="drop-shadow-lg"
            style={{ fontSize: `${height * 0.8}px`, lineHeight: 1 }}
          >
            ❄️
          </span>
        </div>
      </div>
    </div>
  );
};