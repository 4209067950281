import React from 'react';
import { AlertCircle } from 'lucide-react';

export function UrgencyBanner() {
  return (
    <div className="relative py-3 mt-[2rem]">
      <div className="absolute inset-0 bg-gradient-to-r from-red-500 via-green-500 to-red-500 animate-gradient-x" />
      <div className="absolute inset-[2px] bg-gradient-to-r from-red-900 via-green-900 to-red-900 backdrop-blur-sm" />
      <div className="relative max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-center text-center gap-3 text-white">
          <AlertCircle className="w-5 h-5 text-red-500 animate-pulse" />
          <p className="text-[12px] md:text-base flex flex-col sm:flex-row space-x-1 text-center font-medium">
            <span>Create a Merry Christmas memory today!</span>
          </p>
        </div>
      </div>
    </div>
  );
}