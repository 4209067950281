import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { Snowfall } from './components/Snowfall';
import { ChristmasDecorations } from './components/ChristmasDecorations';
import { PackagesPage } from './pages/PackagesPage';
import { SantaCallPage } from './pages/SantaCallPage';
import { SantaCallPagePro } from './pages/SantaCallPagePro';
import { SantaCallPageEnd } from './pages/SantaCallPageEnd'
import { ChatPage } from './pages/ChatPage';
import { SantaLetterPage } from './pages/SantaLetterPage';
import { SantaVideoPage } from './pages/SantaVideoPage';
import { SantaBundlePage } from './pages/SantaBundlePage';
// legal
import { TermsPolicy  } from './pages/legal/Terms';
import { PrivacyPolicy  } from './pages/legal/Privacy';
import { RefundPolicy } from './pages/legal/Refund';
// analytics
import { AnalyticsTiktok } from './components/AnalyticsTiktok';
import { AnalyticsMeta } from './components/AnalyticsMeta';
import { AnalyticsMicrosoft } from './components/AnalyticsMicrosoft';
import { initGoogleAnalytics } from './utils/analyticsGoogle';
//media

// import { MediaStreamProvider } from "./components/MediaStreamContext";

function AppLayout({ children }: { children: React.ReactNode }) {
  const location = useLocation();

  // Exclude the layout for these routes
  const excludeLayout = ['/santa-call'];

  return (
    <div className="min-h-screen bg-gradient-to-b from-red-900 via-green-900 to-red-900 flex flex-col">
      <Snowfall />
      <ChristmasDecorations />
      {!excludeLayout.includes(location.pathname) && <Header />}
      <main className="w-full max-w-[900px] mx-auto p-0 flex-grow">
        {children}
      </main>
      {!excludeLayout.includes(location.pathname) && <Footer />}
    </div>
  );
}

function App() {
  useEffect(() => {
    initGoogleAnalytics('G-PSPJ64G76L'); // Replace with your Measurement ID
  }, []);

  return (
    // <MediaStreamProvider>
    <BrowserRouter>
      <AnalyticsMeta />
      <AnalyticsTiktok />
      <AnalyticsMicrosoft />
      <AppLayout>
        <Routes>
          <Route path="/" element={<PackagesPage />} />
          <Route path="/santa-call" element={<SantaCallPagePro />} />
          <Route path="/end" element={<SantaCallPageEnd />} />
          {/* <Route path="/santa-call2" element={<SantaCallPage />} /> */}
          <Route path="/chat" element={<ChatPage />} />
          <Route path="/santa-letter" element={<SantaLetterPage />} />
          <Route path="/santa-video" element={<SantaVideoPage />} />
          <Route path="/santa-bundle" element={<SantaBundlePage />} />
          <Route path="/terms" element={<TermsPolicy />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/refund" element={<RefundPolicy />} />
        </Routes>
      </AppLayout>
    </BrowserRouter>
    // </MediaStreamProvider>
  );
}

export default App;