import React, { useState } from 'react';
import { Send } from 'lucide-react';

interface SupportFormProps {
  onSubmit: (email: string) => void;
}

export function SupportForm({ onSubmit }: SupportFormProps) {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(email);
    setIsSubmitted(true);
  };

  if (isSubmitted) {
    return (
      <div className="text-center p-4 bg-white/10 rounded-lg backdrop-blur-sm">
        <h4 className="text-white text-lg font-semibold mb-2">🎄 Message Sent!</h4>
        <p className="text-white/90 text-sm">
          Our Chief Technology Elf will get back to you ASAP!
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="email" className="block text-white/90 text-sm font-medium mb-2">
          Your Email Address
        </label>
        <input
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg 
                   text-white placeholder-white/50 focus:outline-none focus:ring-2 
                   focus:ring-red-500/50 backdrop-blur-sm"
          placeholder="santa@northpole.com"
        />
      </div>
      <button
        type="submit"
        className="w-full px-4 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 
                 transition-all transform active:scale-98 shadow-lg hover:shadow-red-500/25 
                 font-medium touch-manipulation text-sm md:text-base flex items-center 
                 justify-center gap-2"
      >
        <Send className="w-4 h-4" />
        Send to the Elves
      </button>
    </form>
  );
}