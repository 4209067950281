import { useEffect } from 'react';

export const AnalyticsMicrosoft = () => {
  useEffect(() => {
    (function (
      c: Window & typeof globalThis,
      l: Document,
      a: string,
      r: string,
      i: string,
      t?: HTMLScriptElement,
      y?: HTMLElement
    ) {
      (c as any)[a] =
        (c as any)[a] ||
        function (...args: any[]) {
          ((c as any)[a].q = (c as any)[a].q || []).push(args);
        };

      t = l.createElement(r) as HTMLScriptElement;
      t.async = true;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0] as HTMLElement;
      y.parentNode?.insertBefore(t, y);
    })(window, document, "clarity", "script", "p9wwffldjp");
  }, []);

  return null; // No visible UI
};