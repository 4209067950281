export const calculateProgress = (elapsed: number, duration: number): number => {
    return Math.min((elapsed / duration) * 100, 100);
  };
  
  export const getProgressColor = (progress: number, duration: number): string => {
    const totalProgress = (progress / 100) * duration;
    
    if (totalProgress <= 60) {
      return '#22c55e'; // green-500
    } else if (totalProgress <= 110) {
      return '#eab308'; // yellow-500
    } else {
      return '#ef4444'; // red-500
    }
  };