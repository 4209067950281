import React, { useEffect, useState } from "react";
import { Mic } from 'lucide-react';
import { PostCallSupport } from "./../chat/PostCallSupport"; // Import PostCallEngagement

interface StartCallPromptProps {
  onClick: () => void;
}

export function StartCallPrompt({ onClick }: StartCallPromptProps) {
  const [showSupport, setShowSupport] = useState(false);

  return (

    
    <div className="flex flex-col items-center justify-center min-h-screen px-4 bg-gradient-to-b from-red-900 to-red-950 ">
      <div className="flex flex-col items-center space-y-8 w-full max-w-sm mx-auto">
        {/* Neon Border Box */}
        <div className="w-full text-center mt-5">
          <div className="bg-black/90 backdrop-blur-lg p-8 rounded-2xl border-2 border-red-500 
                       animate-neon shadow-xl">
            <h1 className="text-3xl font-bold text-white mb-4 tracking-tight">
              🎅 Santa is Ready for Your Call!
            </h1>
            <p className="text-white/90 text-lg">
              Tap the green button below to begin
            </p>
          </div>
        </div>
      
      
        {/* Support Text */}
        {showSupport && <PostCallSupport   
                      onClose={() => setShowSupport(false)}  
                      onSubmit={(data) => {
                      console.log('Form submitted:', data);
                      // Handle form submission
                    }}
                  />}

        <div className="w-full flex justify-center relative top-12">
          <button onClick={() => setShowSupport(true)} className="bg-black/90 backdrop-blur-lg rounded-xl px-6 py-3 
                       border border-white/10">
            <p className="text-white/90 text-base text-center">
              Need help? Contact our elves ❄️ 🔧
            </p>
          </button>
        </div>

        {/* Button */}
        <div className="mt-8 mb-12 relative top-10">
          <button 
            onClick={onClick}
            className="w-20 h-20 rounded-full bg-green-600 flex items-center justify-center
                     transition-all transform hover:scale-105 active:scale-95 
                     shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 
                     focus:ring-green-500/50 touch-manipulation"
            aria-label="Start Call with Santa"
          >
            <Mic className="w-10 h-10 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
}